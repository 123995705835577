'use strict';

Gri.module({
  name: 'slider-alpha',
  ieVersion: null,
  $el: $('.slider-alpha'),
  container: '.slider-alpha',
  fn: function () {
    tofas.sliderAlpha = {
      el: $('.slider-alpha'),
      content: $('.slider-alpha .slider-content'),
      itemTag: $('.slider-alpha .slider-content .item'),
      nav: $('.nav-content'),
      init: function() {
        tofas.sliderAlpha.slider();
        tofas.sliderAlpha.navigation();
        tofas.sliderAlpha.space();
        tofas.sliderAlpha.discoveryButton();
				tofas.sliderAlpha.video.open();
				tofas.sliderAlpha.video.close();
        $(window).on('resize',function(){
					tofas.sliderAlpha.space();
        });
      },
      slider: function() {
        tofas.sliderAlpha.content.slick({
          autoplay:true,
          autoplaySpeed:10000,
          speed:600,
          slidesToShow: 1,
          slidesToScroll: 1,
          pauseOnHover:false,
          arrows: false,
          fade: false,
          dots: false,
          adaptiveHeight: false,
          infinite: true,
          useTransform: true,
          responsive: [{
            breakpoint: 992,
            settings: {
              adaptiveHeight: true,
              dots: true,
              slidesToShow: 1,
              slidesToScroll: 1
            }
          },
          {
            breakpoint: 768,
            settings: {
              dots: true,
              slidesToShow: 1,
              slidesToScroll: 1
            }
          }]
        }).on('afterChange', function(event, slick, currentSlide){
          $('[data-active]').html(currentSlide + 1);
          $('[data-progress]').addClass('start');

          /* Navigation AddClass */
          tofas.sliderAlpha.nav.slick('slickGoTo', currentSlide);
          var currrentNavSlideElem = '.nav-content .slick-slide[data-slick-index="' + currentSlide + '"]';
          $('.nav-content .slick-slide.is-active').removeClass('is-active');
          $(currrentNavSlideElem).addClass('is-active');

        }).on('beforeChange', function(){
		   		$('[data-progress]').removeClass('start');
        });

        // counter
        if($('[data-active]').html() == "") {
          $('[data-active]').html("1");
          $('[data-progress]').addClass('start');
        }
        $('[data-total]').html(tofas.sliderAlpha.itemTag.length);

      },
      navigation: function(){
        tofas.sliderAlpha.nav.on('init', function(event, slick) {
          $('.nav-content .slick-slide.slick-current').addClass('is-active');
        });
        tofas.sliderAlpha.nav.slick({
          slidesToShow: 4,
          slidesToScroll: 1,
          dots: false,
          focusOnSelect: false,
          infinite: false
        });

        tofas.sliderAlpha.nav.on('click', '.slick-slide', function(e) {
          e.preventDefault();
          var goToSingleSlide = $(this).data('slick-index');
          tofas.sliderAlpha.content.slick('slickGoTo', goToSingleSlide);
        });

      },
      space: function() {
        if (window.matchMedia('(max-width: 1329px)').matches) {
          $('.slider-alpha .slider-content .table').css({"padding-left": ""});
          $('a.play').css({"top": "" });
        }else {
          var offsetLeft = $('.header-alpha .container').offset().left;
          var offsetTop = $('.slider-alpha .table h3').offset().top;
          $('.slider-loader').css({"width": (offsetLeft + 20) + "px"});
          $('.slider-alpha .slider-content .table').css({"padding-left": (offsetLeft + 20) + "px"});
          $('.slider-loader').css({"top": (offsetTop +  10) + "px", "width"  : offsetLeft + "px" });
          $('a.play').css({"top": (offsetTop + 10) + "px" });

        }
      },
      discoveryButton: function() {
        $('a.discovery').on('click', function(){
          $('html, body').animate({ scrollTop: ($('[data-scroll-top]').offset().top - $('.header-alpha').height()) }, 500);
        });
      },
      video: {
        open: function() {
          $('.slider-alpha').on('click', 'a.play', function(){
					var video = $(this).attr("data-video");
					if(video != ""){
						tofas.sliderAlpha.content.slick('slickPause');
						$('[data-progress]').removeClass('start');
						$('.slider-alpha .slider-video').addClass('video-opened');
						$('.slider-alpha .slider-video').append("<video loop autoplay poster='/_catalogs/masterpage/images/video/" + video + ".jpg' class='fullscreen'><source src='/_catalogs/masterpage/video/" + video + ".mp4' type='video/mp4'><source src='/_catalogs/masterpage/video/" + video + ".webm' type='video/webm'></video>");
					}else{
						//log("video URL!");
					}
			});
        },
        close: function() {
          $('.slider-alpha .slider-video').on('click', function(){
            $(this).find('video')[0].pause();
            $(this).removeClass('video-opened');
            $(this).html('');
            tofas.sliderAlpha.content.slick('slickPlay');
            $('[data-progress]').addClass('start');
          });
        }
      }
    }

    /**/

    tofas.sliderAlpha.init();

  }
});
